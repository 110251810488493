var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"proposal-content"},[_c('div',{staticClass:"proposal-main"},[_c('user-card',{attrs:{"obj":{ avatar: _vm.avatar, name: _vm.userName, job: _vm.jobName }}}),(_vm.formType.length > 0)?_c('el-form',{ref:"ruleForm",staticClass:"reset-form",attrs:{"model":_vm.form,"show-message":true,"inline-message":"","label-width":"80px","label-position":"top"}},[_c('div',{staticClass:"form-list"},[_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"rules":[
              {
                required: _vm.formType[0].isMust,
                message: ((_vm.formType[0].showName) + "不能为空"),
                trigger: 'blur',
                validator: _vm.formType[0].isMust ? _vm.$checkTrim : ''
              },
              { min: 1, max: 50, message: ((_vm.formType[0].showName) + "限50字之内"), trigger: 'blur' }
            ],"label":_vm.formType[0].showName || '标题',"prop":"title"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 3 },"placeholder":"请输入","maxlength":"50","show-word-limit":"","resize":"none"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formType[1].showName || '内容',"rules":[
              {
                required: _vm.formType[1].isMust,
                message: ((_vm.formType[1].showName) + "不能为空"),
                trigger: 'blur',
                validator: _vm.formType[1].isMust ? _vm.$checkTrim : ''
              },
              {
                min: 1,
                max: 800,
                message: ((_vm.formType[1].showName) + "限800字之内"),
                trigger: 'blur'
              }
            ],"prop":"customContent"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 5 },"placeholder":"请输入","maxlength":"800","show-word-limit":"","resize":"none"},model:{value:(_vm.form.customContent),callback:function ($$v) {_vm.$set(_vm.form, "customContent", $$v)},expression:"form.customContent"}})],1)],1)]),_vm._l((_vm.form.addCustomMatterInputList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"customer-title"},[_c('span',{staticClass:"item"},[_vm._v("自定义事项")]),_c('i',{staticClass:"el-icon-delete delete",on:{"click":function($event){return _vm.deleteItem(index)}}})]),_c('div',{staticClass:"form-list"},[_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":"事项","prop":'addCustomMatterInputList.' + index + '.matterName',"rules":[
                {
                  required: true,
                  message: '自定义事项不能为空',
                  trigger: 'blur',
                  validator: _vm.$checkTrim
                },
                { min: 1, max: 50, message: "自定义事项限50字以内", trigger: 'blur' }
              ]}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 3 },"placeholder":"请输入","maxlength":"50","show-word-limit":"","resize":"none"},model:{value:(item.matterName),callback:function ($$v) {_vm.$set(item, "matterName", $$v)},expression:"item.matterName"}})],1)],1),_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":"完成结果","prop":'addCustomMatterInputList.' + index + '.matterResult',"rules":[
                {
                  required: true,
                  message: '自定义事项完成结果不能为空',
                  trigger: 'blur',
                  validator: _vm.$checkTrim
                },
                {
                  min: 1,
                  max: 800,
                  message: '自定义事项完成结果限800字以内',
                  trigger: 'blur'
                }
              ]}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"placeholder":"请输入","type":"textarea","autosize":{ minRows: 1, maxRows: 5 },"maxlength":"800","show-word-limit":"","resize":"none"},model:{value:(item.matterResult),callback:function ($$v) {_vm.$set(item, "matterResult", $$v)},expression:"item.matterResult"}})],1)],1),_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":"备注","prop":"remarks","rules":[
                {
                  required: false,
                  message: '',
                  trigger: 'blur'
                },
                {
                  min: 1,
                  max: 800,
                  message: '自定义事项备注限50字以内',
                  trigger: 'blur'
                }
              ]}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 3 },"placeholder":"请输入","maxlength":"50","show-word-limit":"","resize":"none"},model:{value:(item.remarks),callback:function ($$v) {_vm.$set(item, "remarks", $$v)},expression:"item.remarks"}})],1)],1)])])}),(_vm.isEnabled)?_c('div',{staticClass:"form-list customer-card",on:{"click":_vm.addItem}},[_c('i',{staticClass:"el-icon-circle-plus-outline file-icon"},[_c('span',{staticClass:"self"},[_vm._v("添加自定义事项")])])]):_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"result-bottom"},[(_vm.type === 'add')?_c('el-button',{staticClass:"white",on:{"click":_vm.handleSaveDrafts}},[_vm._v("保存草稿")]):_vm._e(),_c('el-button',{staticClass:"blue",attrs:{"type":"primary"},on:{"click":_vm.handleNode}},[_vm._v("提交")])],1),_c('approvalExplanation',{attrs:{"showDialog":_vm.showDialog,"type":1,"modelType":"team"}}),_c('approvalNode',{attrs:{"display":_vm.showNodeDialog,"id":_vm.orgId,"innovateLevel":_vm.form.contributeCategory,"modelType":"team"},on:{"before-close":_vm.handleClose,"handleConfirm":_vm.handleConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }