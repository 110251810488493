<template>
  <div class="proposal-content" v-loading="loading">
    <div class="proposal-main">
      <user-card :obj="{ avatar: avatar, name: userName, job: jobName }" />
      <el-form
        :model="form"
        :show-message="true"
        inline-message
        ref="ruleForm"
        label-width="80px"
        label-position="top"
        v-if="formType.length > 0"
        class="reset-form"
      >
        <div class="form-list">
          <div class="item">
            <el-form-item
              :rules="[
                {
                  required: formType[0].isMust,
                  message: `${formType[0].showName}不能为空`,
                  trigger: 'blur',
                  validator: formType[0].isMust ? $checkTrim : ''
                },
                { min: 1, max: 50, message: `${formType[0].showName}限50字之内`, trigger: 'blur' }
              ]"
              :label="formType[0].showName || '标题'"
              prop="title"
            >
              <el-input
                v-filteremoji
                v-model="form.title"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 3 }"
                placeholder="请输入"
                maxlength="50"
                show-word-limit
                resize="none"
              ></el-input>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item
              :label="formType[1].showName || '内容'"
              :rules="[
                {
                  required: formType[1].isMust,
                  message: `${formType[1].showName}不能为空`,
                  trigger: 'blur',
                  validator: formType[1].isMust ? $checkTrim : ''
                },
                {
                  min: 1,
                  max: 800,
                  message: `${formType[1].showName}限800字之内`,
                  trigger: 'blur'
                }
              ]"
              prop="customContent"
            >
              <el-input
                v-filteremoji
                type="textarea"
                v-model="form.customContent"
                :autosize="{ minRows: 1, maxRows: 5 }"
                placeholder="请输入"
                maxlength="800"
                show-word-limit
                resize="none"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div v-for="(item, index) in form.addCustomMatterInputList" :key="index">
          <div class="customer-title">
            <span class="item">自定义事项</span>
            <i class="el-icon-delete delete" @click="deleteItem(index)"></i>
          </div>
          <div class="form-list">
            <div class="item">
              <el-form-item
                label="事项"
                :prop="'addCustomMatterInputList.' + index + '.matterName'"
                :rules="[
                  {
                    required: true,
                    message: '自定义事项不能为空',
                    trigger: 'blur',
                    validator: $checkTrim
                  },
                  { min: 1, max: 50, message: `自定义事项限50字以内`, trigger: 'blur' }
                ]"
              >
                <el-input
                  v-filteremoji
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 3 }"
                  v-model="item.matterName"
                  placeholder="请输入"
                  maxlength="50"
                  show-word-limit
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item
                label="完成结果"
                :prop="'addCustomMatterInputList.' + index + '.matterResult'"
                :rules="[
                  {
                    required: true,
                    message: '自定义事项完成结果不能为空',
                    trigger: 'blur',
                    validator: $checkTrim
                  },
                  {
                    min: 1,
                    max: 800,
                    message: '自定义事项完成结果限800字以内',
                    trigger: 'blur'
                  }
                ]"
              >
                <el-input
                  v-filteremoji
                  v-model="item.matterResult"
                  placeholder="请输入"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  maxlength="800"
                  show-word-limit
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item
                label="备注"
                prop="remarks"
                :rules="[
                  {
                    required: false,
                    message: '',
                    trigger: 'blur'
                  },
                  {
                    min: 1,
                    max: 800,
                    message: '自定义事项备注限50字以内',
                    trigger: 'blur'
                  }
                ]"
              >
                <el-input
                  v-filteremoji
                  v-model="item.remarks"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 3 }"
                  placeholder="请输入"
                  maxlength="50"
                  show-word-limit
                  resize="none"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-list customer-card" @click="addItem" v-if="isEnabled">
          <i class="el-icon-circle-plus-outline file-icon"
            ><span class="self">添加自定义事项</span></i
          >
        </div>
      </el-form>
    </div>
    <div class="result-bottom">
      <el-button @click="handleSaveDrafts" v-if="type === 'add'" class="white">保存草稿</el-button>
      <el-button type="primary" @click="handleNode" class="blue">提交</el-button>
    </div>
    <approvalExplanation :showDialog="showDialog" :type="1" modelType="team" />
    <approvalNode
      :display="showNodeDialog"
      @before-close="handleClose"
      :id="orgId"
      :innovateLevel="form.contributeCategory"
      modelType="team"
      @handleConfirm="handleConfirm"
    />
  </div>
</template>

<script>
import userCard from '@/components/initiate/user-card'
import approvalExplanation from '@/components/initiate/approval-explanation'
import approvalNode from '@/components/initiate/approval-node'
import {
  getDataDraft,
  postDataDraftSave,
  getformDetail,
  postCustomerEdit,
  postCustomerAdd,
  getCustomerDetail
} from '@/api/api_customer'
import { mapState } from 'vuex'
export default {
  name: 'initiate-proposal',
  components: {
    userCard,
    approvalExplanation,
    approvalNode
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    id: {
      type: String,
      default: ''
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      isEnabled: 0,
      name: sessionStorage.getItem('name'),
      code: sessionStorage.getItem('code'),
      modelCode: 'team',
      formObj: {},
      processObj: {},
      loading: false,
      formIds: {},
      formType: [],
      orgId: 0,
      orgName: '',
      orgList: [],
      category: [
        { id: 56, proposalDesc: '应用2' },
        { id: 57, proposalDesc: '应用1' }
      ],
      showNodeDialog: false,
      showDialog: false,
      processExampleDtoList: [], //流程选择
      filePathList: [], //附件列表
      form: {
        title: '', //标题
        customContent: '', //内容
        addCustomMatterInputList: []
      },
      rules: {}
    }
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      jobName: state =>
        state.userInfo.jobList && state.userInfo.jobList.length > 0
          ? state.userInfo.jobList[0].jobName
          : ''
    })
  },
  methods: {
    addItem() {
      if (this.form.addCustomMatterInputList.length > 14) {
        this.$warning('自定义事项最多可添加15项')
        return false
      }
      let obj = {
        matterName: '',
        matterResult: '',
        remarks: ''
      }
      this.form.addCustomMatterInputList.push(obj)
    },
    deleteItem(index) {
      this.form.addCustomMatterInputList.splice(index, 1)
    },
    /**
     * 动态表单字段
     */
    handleFormList() {
      getformDetail({ dimensionCode: this.code })
        .then(res => {
          if (res.data) {
            let { content, isEnabled } = res.data
            this.isEnabled = isEnabled
            let keys = Object.keys(this.form)
            for (let i = 0; i < 2; i++) {
              this.$set(this.formType, i, content[i])
            }
          } else {
            this.formType = []
          }
        })
        .catch(err => {})
    },
    /**
     * 提案详情
     */
    handleDetail() {
      let params = {
        customCode: this.id,
        dimensionCode: sessionStorage.getItem('code')
      }
      getCustomerDetail(params)
        .then(res => {
          if (res.data) {
            let { isOpenMatter, webMatterOutputList } = res.data
            this.formObj = res.data
            this.form.title = webMatterOutputList[0].matterResult || ''
            this.form.customContent = webMatterOutputList[1].matterResult || ''
            this.isEnabled = isOpenMatter
            for (let j = 0; j < 2; j++) {
              let { formType, matterMust, matterName } = webMatterOutputList[j]
              this.$set(this.formType, j, {
                isMust: matterMust,
                showName: matterName,
                formCode: formType
              })
            }
            for (let i = 2; i < webMatterOutputList.length; i++) {
              let el = webMatterOutputList[i]
              let { formType, matterName, matterResult, remarks } = el
              let obj = {}
              this.$set(obj, 'formType', formType)
              this.$set(obj, 'matterName', matterName)
              this.$set(obj, 'matterResult', matterResult)
              this.$set(obj, 'remarks', remarks)
              this.form.addCustomMatterInputList.push(obj)
            }
          } else {
            this.initiateObj = {}
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleNode() {
      if (this.formType.length < 1) {
        this.$$msgError('暂无模版数据')
        return false
      }
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.showNodeDialog = true
        } else {
          return false
        }
      })
    },
    /**
     * 关闭审批节点弹框
     */
    handleClose(val) {
      this.showNodeDialog = val
    },
    /**
     * 保存草稿箱
     */
    handleSaveDrafts() {
      if (this.formType.length < 1) {
        this.$$msgError('暂无模版数据')
        return false
      }
      let { title, customContent, addCustomMatterInputList } = this.form
      let arr = []
      let item = [
        { title: this.formType[0].showName || '标题', val: title },
        { title: this.formType[1].showName || '内容', val: customContent }
      ]
      for (let i = 0; i < this.formType.length; i++) {
        let obj = {}
        let el = this.formType[i]
        this.$set(obj, 'formType', el.formCode)
        this.$set(obj, 'matterName', item[i].title)
        this.$set(obj, 'matterResult', item[i].val)
        this.$set(obj, 'sorts', i)
        arr.push(obj)
      }

      addCustomMatterInputList.forEach((el, index) => {
        this.$set(el, 'formType', 12)
        this.$set(el, 'sorts', index + 2)
      })
      let params = {
        dimensionCode: this.code,
        addCustomMatterInputList: [...arr, ...addCustomMatterInputList],
        isOpenMatter: this.isEnabled //是否开启自定义
      }
      postDataDraftSave(this.$filterParamsTrim(params))
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.form.addCustomMatterInputList = []
            this.getDataDraft()
          }
        })
        .catch(err => {})
    },
    /**
     * 草稿箱回显
     */
    getDataDraft() {
      getDataDraft({ dimensionCode: this.code })
        .then(res => {
          if (!res.data) return false
          let {
            title, //标题
            customContent, //内容
            addCustomMatterInputList
          } = res.data
          this.form.title = addCustomMatterInputList[0].matterResult || ''
          this.form.customContent = addCustomMatterInputList[1].matterResult || ''
          for (let i = 2; i < addCustomMatterInputList.length; i++) {
            let el = addCustomMatterInputList[i]
            let { formType, matterName, matterResult, remarks } = el
            let obj = {}
            this.$set(obj, 'formType', formType)
            this.$set(obj, 'matterName', matterName)
            this.$set(obj, 'matterResult', matterResult)
            this.$set(obj, 'remarks', remarks)
            this.form.addCustomMatterInputList.push(obj)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async handleAdd(params) {
      try {
        if (this.type === 'add') {
          return await postCustomerAdd({
            ...params,
            dimensionCode: sessionStorage.getItem('code'),
            isOpenMatter: this.isEnabled
          })
        } else {
          return await postCustomerEdit({
            ...params,
            dimensionCode: sessionStorage.getItem('code'),
            isOpenMatter: this.isEnabled,
            customCode: this.id
          })
        }
      } catch (err) {
        return err
      }
    },
    /**
     * 提交
     */
    handleConfirm(processExampleDtoList, processTemplateId, processTemplateName) {
      let { title, addCustomMatterInputList, customContent } = this.form
      let arr = []
      let item = [
        { title: this.formType[0].showName || '标题', val: title },
        { title: this.formType[1].showName || '内容', val: customContent }
      ]
      for (let i = 0; i < this.formType.length; i++) {
        let obj = {}
        let el = this.formType[i]
        this.$set(obj, 'formType', el.formCode)
        this.$set(obj, 'matterName', item[i].title)
        this.$set(obj, 'matterResult', item[i].val)
        this.$set(obj, 'sorts', i)
        arr.push(obj)
      }
      addCustomMatterInputList.forEach((el, index) => {
        this.$set(el, 'formType', 12)
        this.$set(el, 'sorts', index + 2)
      })
      let params = {
        title,
        addCustomMatterInputList: [...arr, ...addCustomMatterInputList],
        customContent,
        processExampleDtoList,
        processTemplateId,
        processTemplateName
      }
      this.loading = true
      this.handleAdd(this.$filterParamsTrim(params))
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.showNodeDialog = false
            let path =
              sessionStorage.getItem('code') === 'customize1'
                ? 'customer-initiate'
                : 'customer2-initiate'
            if (this.type === 'add') {
              path =
                sessionStorage.getItem('code') === 'customize1'
                  ? 'customer-channel'
                  : 'customer2-channel'
            }
            this.$router.push({
              name: path,
              query: {
                moduleName: this.type === 'edit' ? this.moduleName : null
              }
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('服务异常')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.type === 'add') {
      //新增-草稿箱
      this.handleFormList()
      this.getDataDraft()
    } else {
      //编辑-详情
      this.handleDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/addProposal.scss';
.customer-title {
  font-size: 14px;
  font-weight: 400;
  color: $lightblack;
  line-height: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  .item {
    flex: 1;
  }
  .delete {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    @include font-hover-color();
  }
}
.customer-card {
  cursor: pointer;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $darkgrey;
  @include font-hover-color();
  .file-icon {
    font-size: 15px;
    font-weight: 400;

    line-height: 15px;
    cursor: pointer;

    .self {
      margin-left: 8px;
    }
  }
}
</style>
